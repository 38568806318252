import { usePaypalStore } from './paypal.store'
import { env } from '@/src/shared/config'

export function paypalScriptLoad(subscribe: boolean = false): Promise<void> {
  const store = usePaypalStore()
  if (store.scriptLoaded) return Promise.resolve()
  return new Promise(function (resolve) {
    const scriptNode = document.createElement('script')
    scriptNode.setAttribute('data-client-token', store.clientToken)
    scriptNode.onload = () => {
      store.scriptLoaded = true
      resolve(undefined)
    }

    const paypalURL = new URL('https://www.paypal.com/sdk/js')
    paypalURL.searchParams.append('components', 'buttons,funding-eligibility')
    paypalURL.searchParams.append('client-id', store.clientId)
    paypalURL.searchParams.append('vault', 'true')
    if (subscribe) {
      paypalURL.searchParams.append('intent', 'subscription')
    }
    if (!env.IS_PROD) {
      paypalURL.searchParams.append('debug', 'true')
    }

    scriptNode.src = paypalURL.href
    document.body.appendChild(scriptNode)
  })
}
