import { PAYMENT_ERROR_UNKOWN } from '../../config'
import { usePaymentModalStore } from './modal.store'
import { paymentSolidGateOrderStatusPost } from '@/src/shared/api'
import { IAnalytics } from '@/src/shared/lib/analytics'

const DELAY_REPEAT_ORDER_CHECK = 1000

type PaymentApiPaymentSolidGateOrderStatusResponseStatus =
  | 'success'
  | 'processing'
  | 'fail'

type PaymentApiPaymentSolidGateOrderStatusResponse = {
  status: PaymentApiPaymentSolidGateOrderStatusResponseStatus
  orderId: string
  priceFull: number
  description: {
    text: string
  }
  createdAt: number
  verify?: {
    formUrl: string
    failUrl: string
    successUrl: string
  }
}

export async function orderStatus($analytics: IAnalytics) {
  const store = usePaymentModalStore()
  if (!store.open) return

  const response = await paymentSolidGateOrderStatusPost<PaymentApiPaymentSolidGateOrderStatusResponse>(
    store.orderId
  )

  if (response.isOk && response.data?.status === 'processing') {
    const secureSrc = response.data.verify?.formUrl
    $analytics.event('solid_status_check', {
      type: 'processing',
      kind: secureSrc ? '3ds' : 'regular',
    })
    if (secureSrc) {
      store.secureSrc = secureSrc
      store.screenStep = 'secure'
    } else {
      setTimeout(() => orderStatus($analytics), DELAY_REPEAT_ORDER_CHECK)
    }
  } else if (response.isOk && response.data?.status === 'success') {
    $analytics.event('solid_status_check', {
      type: 'approved',
      kind: store.secureSrc ? '3ds' : 'regular',
    })
    store.screenStep = 'success'
  } else if ((response.isError || response.isInvalid) && response.data?.error) {
    let errorMessage = response.data?.error.message
    if (!errorMessage) {
      errorMessage = (response.data?.error as any)?.errorsDetails
        .recommended_message_for_user as string
    }
    $analytics.event('solid_status_check', {
      type: 'declined',
      kind: store.secureSrc ? '3ds' : 'regular',
      message: errorMessage,
    })
    store.screenStep = 'error'
    store.errorMessage = errorMessage || ''
    store.errorCode = ''
  } else {
    $analytics.event('solid_status_check', {
      type: 'declined',
      kind: store.secureSrc ? '3ds' : 'regular',
      message: PAYMENT_ERROR_UNKOWN,
    })
    store.screenStep = 'error'
    store.errorMessage = PAYMENT_ERROR_UNKOWN
    store.errorCode = ''
  }
}
