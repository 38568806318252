import {
  paymentSolidGateAutoTopUpGet,
  paymentSolidGateAutoTopUpPost,
} from '@/src/shared/api'

interface ApiResponseAutoTopUpGet {
  enabled: boolean
}

interface ApiResponseAutoTopUpPost {
  success: boolean
}

export async function load(): Promise<boolean | null | undefined> {
  const response = await paymentSolidGateAutoTopUpGet<ApiResponseAutoTopUpGet>()
  return response.data?.enabled
}

export async function save(enable: boolean): Promise<boolean> {
  const response = await paymentSolidGateAutoTopUpPost<ApiResponseAutoTopUpPost>(
    enable
  )
  return response.data?.success || false
}
