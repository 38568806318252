import { paypalSubscriptionCapturePost } from '@/src/shared/api'

type PaypalSubscriptionCaptureResponse = {
  success: boolean
}

export async function paypalSubscriptionCapture(id: string): Promise<boolean> {
  const response = await paypalSubscriptionCapturePost<PaypalSubscriptionCaptureResponse>(
    id
  )
  if (response.isOk && response.data) {
    return response.data.success
  }
  return false
}
