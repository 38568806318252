import { PaymentBundle } from '../types'

export function generate(props: Partial<PaymentBundle>): PaymentBundle {
  return Object.assign(
    {
      sku: '',
      coins: 0,
      title: '',
      subtitle: '',
      price: '',
      amount: 0,
      label: undefined,
    },
    props
  )
}

export function first(): PaymentBundle {
  return generate({
    sku: '300_coins',
    coins: 300,
    amount: 0.99,
    title: '300',
    subtitle: '',
    price: '$0.99',
  })
}

export function dollars5(): PaymentBundle {
  return generate({
    sku: '1500_coins',
    coins: 1500,
    amount: 4.99,
    title: '1500',
    subtitle: '',
    price: '$4.99',
  })
}

export function baseMany(): PaymentBundle[] {
  return [
    generate({
      sku: '150_coins',
      coins: 150,
      amount: 1.99,
      title: '2 min',
      price: '$1.99',
    }),
    generate({
      sku: '650_coins',
      coins: 650,
      amount: 6.99,
      title: '7 min',
      subtitle: 'Save 15%',
      price: '$6.99',
    }),
    generate({
      sku: '1050_coins',
      coins: 1050,
      amount: 10.99,
      title: '11 min',
      subtitle: 'Save 20%',
      price: '$10.99',
    }),
    generate({
      sku: '2550_coins',
      coins: 2550,
      amount: 24.99,
      // label: 'popular',
      title: '25 min',
      subtitle: '25% OFF',
      price: '$24.99',
    }),
    generate({
      sku: '7050_coins',
      coins: 7050,
      amount: 64.99,
      title: '60 min',
      subtitle: 'Save 30%',
      price: '$64.99',
    }),
    generate({
      sku: '11000_coins',
      coins: 11000,
      amount: 94.99,
      // label: 'best value',
      title: '95 min',
      subtitle: '35% OFF',
      price: '$94.99',
    }),
  ]
}

export function commeet(): PaymentBundle[] {
  return baseMany().filter((bundle) =>
    ['1050_coins', '2550_coins', '11000_coins'].includes(bundle.sku)
  )
}

export function cheap(): PaymentBundle[] {
  return [
    generate({
      sku: '97454675-edc4-44ba-a1b1-4195da795bfd',
      coins: 1200,
      amount: 9.99,
      // label: 'best value',
      title: '10 min',
      // subtitle: '35% OFF',
      price: '$9.99',
    }),
    generate({
      sku: 'c0a272ad-88c7-4735-9141-bc4e86c89889',
      coins: 4200,
      amount: 24.99,
      label: '10 Min Free',
      title: '35 min',
      // subtitle: '35% OFF',
      price: '$24.99',
    }),
    generate({
      sku: '55f59882-9cfb-4f59-9d00-2ec0c8124fb3',
      coins: 24000,
      amount: 99.99,
      label: '100 Min Free',
      title: '200 min',
      // subtitle: '35% OFF',
      price: '$99.99',
    }),
  ]
}

export function commeetPremiumWeekly(): PaymentBundle[] {
  return [
    generate({
      sku: 'b53a2c1e-f978-43b7-81a9-c2038b41762c',
      coins: 12000,
      amount: 71.99,
      label: '100 Min FREE',
      title: '6 months',
      subtitle: '-55% OFF',
      price: '$2.99',
    }),
    generate({
      sku: '553c09ad-cd0f-4339-9d03-64b14b958c1f',
      coins: 2400,
      amount: 23.99,
      label: '20 Min FREE',
      title: '1 month',
      subtitle: '-25% OFF',
      price: '$5.99',
    }),
    generate({
      sku: '9988ba87-dae9-4ecb-881a-29c6efdab605',
      coins: 600,
      amount: 7.99,
      label: '5 Min FREE',
      title: '1 week',
      // subtitle: '',
      price: '$7.99',
    }),
  ]
}

export function commeetPremiumMonthly(): PaymentBundle[] {
  return [
    generate({
      sku: '8d158ceb-096e-4b2c-881b-b38672de31e6',
      coins: 7200,
      amount: 29.99,
      label: '60 Min FREE',
      title: '6 months',
      subtitle: '-50% OFF',
      price: '$4.99',
    }),
    generate({
      sku: '6bf9980a-359b-400b-a7ca-a8b6b38a5ff5',
      coins: 3600,
      amount: 23.99,
      label: '30 Min FREE',
      title: '3 months',
      subtitle: '-20% OFF',
      price: '$7.99',
    }),
    generate({
      sku: 'd2d4f8e2-a694-4e00-a0df-db12963cce79',
      coins: 1200,
      amount: 9.99,
      label: '10 Min FREE',
      title: '1 month',
      // subtitle: '',
      price: '$9.99',
    }),
  ]
}
