import { PAYMENT_ERROR_UNKOWN } from '../../config'
import { PaymentError, PaymentErrorDescription } from '../payment.error'
import { usePaymentModalStore } from './modal.store'
import { paymentSolidGateRecurringPost } from '@/src/shared/api'

type PaymentApiPaymentSolidGateRecurringResponse = {
  orderId: string
}

export async function recurring(): Promise<boolean> {
  const store = usePaymentModalStore()
  if (!store.selectedBundleSku)
    throw new PaymentError(PaymentErrorDescription.NoSKU)
  if (!store.selectedCardId)
    throw new PaymentError(PaymentErrorDescription.NoCardId)

  const response = await paymentSolidGateRecurringPost<PaymentApiPaymentSolidGateRecurringResponse>(
    store.selectedBundleSku,
    store.selectedCardId
  )
  if (response.isOk && response.data?.orderId) {
    store.orderId = parseInt(response.data.orderId)
    return true
  } else if (response.data?.error) {
    store.errorMessage = response.data?.error.message || PAYMENT_ERROR_UNKOWN
  } else {
    store.errorMessage = PAYMENT_ERROR_UNKOWN
  }
  return false
}
