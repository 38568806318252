import { ConfirmEmailModalEntry } from '../types'
import { modal } from './modal.service'
import { state } from './confirmEmail.store'

const EVENT_CLOSE = 'close'

export function open(entry: ConfirmEmailModalEntry) {
  state.openCounts++
  modal.open({
    entry,
  })
}

export function close() {
  modal.close()
}

export function onCloseOne(callback: Function) {
  modal.actionOne(EVENT_CLOSE, callback)
}

export function onClose(callback: Function) {
  modal.actionOn(EVENT_CLOSE, callback)
}

export function offClose(callback: Function) {
  modal.actionOff(EVENT_CLOSE, callback)
}
