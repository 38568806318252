// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vdvv[data-v-20ebbe81]{width:100%;justify-content:center;margin-left:auto;margin-right:auto}.vdvv[data-v-20ebbe81],.U-Ns[data-v-20ebbe81]{position:relative;display:flex}.U-Ns[data-v-20ebbe81]{align-items:center;color:#6f7580;font-family:\"Poppins\",sans-serif;font-size:13px;font-style:normal;font-weight:500;line-height:16px;letter-spacing:-.1px}.UO7I[data-v-20ebbe81]{display:flex;align-items:center;padding:0 4px;color:#3b4048;cursor:pointer}.UO7I[data-v-20ebbe81]:hover{background:#e5e5ea;border-radius:4px}.xd-o[data-v-20ebbe81]{margin-left:4px}.CPBG[data-v-20ebbe81]{position:absolute;top:calc(100% + 4px);right:0;background-color:#fff;border:1px solid #e8ebf3;box-sizing:border-box;box-shadow:0 1px 6px rgba(0,0,0,.08);border-radius:6px}.ZtIQ[data-v-20ebbe81]{display:flex;align-items:center;justify-content:space-between;width:206px;height:40px;padding:12px;color:#6f7580;cursor:pointer}._3FNc[data-v-20ebbe81],.ZtIQ[data-v-20ebbe81]:hover{color:#6a1db1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comp": "vdvv",
	"comp__trigger": "U-Ns",
	"comp__trigger__value": "UO7I",
	"comp__trigger__icon": "xd-o",
	"comp__dropdown": "CPBG",
	"comp__dropdown__item": "ZtIQ",
	"comp__dropdown__active": "_3FNc"
};
module.exports = ___CSS_LOADER_EXPORT___;
