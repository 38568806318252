enum AdditionalFieldName {
  ZipCode = 'zipСode',
  ArgentinaDni = 'argentinaDni',
  BangladeshNic = 'bangladeshNic',
  BoliviaCi = 'boliviaCi',
  BrazilCpf = 'brazilCpf',
  CameroonCni = 'cameroonCni',
  ChileCi = 'chileCi',
  ChinaId = 'chinaId',
  ColombiaCc = 'colombiaCc',
  CostaRicaCi = 'costaRicaCi',
  DominicanaId = 'dominicanaId',
  EcuadorCi = 'ecuadorCi',
  ElSalvadorId = 'elSalvadorId',
  EgyptId = 'egyptId',
  GhanaCard = 'ghanaCard',
  GuatemalaCui = 'guatemalaCui',
  IndiaPan = 'indiaPan',
  IndonesiaNik = 'indonesiaNik',
  JapanId = 'japanId',
  KenyaId = 'kenyaId',
  MalaysiaNric = 'malaysiaNric',
  MexicoCurp = 'mexicoCurp',
  MoroccoCnie = 'moroccoCnie',
  NigeriaNin = 'nigeriaNin',
  PanamaId = 'panamaId',
  ParaguayCi = 'paraguayCi',
  PeruDni = 'peruDni',
  PhilipinesPsn = 'philipinesPsn',
  SenegalCni = 'senegalCni',
  SouthAfricaId = 'southAfricaId',
  TanzaniaId = 'tanzaniaId',
  ThailandId = 'thailandId',
  TurkeyTcKimlikNo = 'turkeyTcKimlikNo',
  UgandaNic = 'ugandaNic',
  UriguayCi = 'uriguayCi',
  VietnamVnid = 'vietnamVnid'
}

export default AdditionalFieldName
