import { PaymentTransaction, PaymentTransactionRefundStatus } from '../types'
import { usePaymentTransactionStore } from './transaction.store'
import {
  paymentTransactionGet,
  paymentSolidGateRefundPost,
} from '@/src/shared/api'

type PaymentApiPaymentSolidTransactionItemDescriptionType = 1

type PaymentApiPaymentSolidTransactionItemDescription = {
  details: {
    coins?: number
  }
  simpleText: string
  type: PaymentApiPaymentSolidTransactionItemDescriptionType
}

type PaymentApiTransactionItem = {
  id: number
  amount: number
  currency: string
  createdAt: number
  sku: string
  description: PaymentApiPaymentSolidTransactionItemDescription
  cardBrand?: string
  cardNumber?: string
  cardBank?: string
  order?: string
  refundStatus?: 'created' | 'refunded' | 'created_from_notification'
}

type PaymentApiTransaction = {
  transactions: PaymentApiTransactionItem[]
}

function formatDate(createdAt: number): string {
  const date = new Date(createdAt * 1000)
  if (!Intl) return date.toLocaleString()
  return new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(date)
}

function mapper(item: PaymentApiTransactionItem): PaymentTransaction {
  return {
    id: item.id,
    coins: item.description?.details?.coins || 0,
    cost: item.amount,
    refundStatus: item.refundStatus as PaymentTransactionRefundStatus,
    createdAt: item.createdAt,
    date: formatDate(item.createdAt),
  }
}

export async function load() {
  const store = usePaymentTransactionStore()
  const response = await paymentTransactionGet<PaymentApiTransaction>()
  if (response.data?.transactions) {
    store.transactions = []
    response.data.transactions.map(mapper).forEach(store.transactionSet)
  }
  store.loaded = true
}

export async function get(reaload: boolean = false) {
  const store = usePaymentTransactionStore()
  if (!store.loaded || reaload) return await load()
  return store.transactions
}

export async function refund(id: number) {
  await paymentSolidGateRefundPost(id)
}
