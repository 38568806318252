import { IError, ErrorBase } from '@/src/shared/lib/error'

export const ERROR_NAME = 'Payment'

export enum PaymentErrorDescription {
  'Unknown' = 'Unknown',
  'NoSKU' = 'NoSKU',
  'NoCardId' = 'NoCardId',
  'NoMerchantData' = 'NoMerchantData',
  'NoSolidSDK' = 'NoSolidSDK',
  'NoSolidSDKForm' = 'NoSolidSDKForm',
  'NoSelectedBundle' = 'NoSelectedBundle',
}

export class PaymentError extends ErrorBase implements IError {
  constructor(description: PaymentErrorDescription) {
    super(ERROR_NAME, description)
  }
}
