import { defineStore } from 'pinia'
import { PaymentTransaction } from '../types'

type PaymentTransactionStoreState = {
  loaded: boolean
  transactions: PaymentTransaction[]
}

export const usePaymentTransactionStore = defineStore('paymentTransaction', {
  state: (): PaymentTransactionStoreState => {
    return {
      loaded: false,
      transactions: [],
    }
  },
  getters: {},
  actions: {
    transactionSet(item: PaymentTransaction) {
      // const index = this.transactions.findIndex((value) => value.id === item.id)
      // if (index >= 0) return
      this.transactions.push(item)
      // this.transactions.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    },
    findById(id: number): PaymentTransaction | undefined {
      return this.transactions.find(
        (item: PaymentTransaction) => item.id === id
      )
    },
  },
})
