// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xsD0[data-v-17ca2be0]{max-width:436px;margin-left:auto;margin-right:auto;text-align:left;color:#c8c4cc;font-family:\"Poppins\",sans-serif;font-size:12px;font-style:normal;font-weight:400;line-height:16px;letter-spacing:-.23px}@media screen and (min-width:1024px){.xsD0[data-v-17ca2be0]{text-align:center}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comp": "xsD0"
};
module.exports = ___CSS_LOADER_EXPORT___;
