import { usePaypalStore } from './paypal.store'
import { paypalOrderInitPost } from '@/src/shared/api'

type PaypalOrderInitResponse = {
  orderId?: string
  subscriptionId?: string
}

export async function paypalOrderInit(sku: string): Promise<void> {
  const store = usePaypalStore()
  const response = await paypalOrderInitPost<PaypalOrderInitResponse>(sku)
  if (response.isOk && response.data) {
    const data = response.data
    if (data.orderId) store.orderId = data.orderId
    if (data.subscriptionId) store.subscriptionId = data.subscriptionId
  }
}
