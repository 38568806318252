import { PaymentModalOpenPayload, PaymentModalClosePayload } from '../../types'
import {
  MODAL_NAME,
  MODAL_ACTION_PURCHASE_CLOSE,
  MODAL_ACTION_PURCHASE_STARTED,
  MODAL_ACTION_PURCHASE_SUCCESS,
  MODAL_ACTION_PURCHASE_ERROR,
} from '../../config'
import { ModalExternalService } from '@/src/shared/lib/modal'
import { eventBus } from '@/src/shared/lib/eventBus'

class PaymentModalService extends ModalExternalService {
  constructor() {
    super(MODAL_NAME, eventBus)
  }

  public open(payload: PaymentModalOpenPayload) {
    super.open(payload)
  }

  public close(payload?: PaymentModalClosePayload) {
    super.close(payload)
  }

  public actionPurchaseStarted(): void {
    this.action(MODAL_ACTION_PURCHASE_STARTED)
  }

  public actionOnPurchaseStarted(callback: Function) {
    this.actionOn(MODAL_ACTION_PURCHASE_STARTED, callback)
  }

  public actionOffPurchaseStarted(callback: Function) {
    this.actionOff(MODAL_ACTION_PURCHASE_STARTED, callback)
  }

  public actionPurchaseSuccess(): void {
    this.action(MODAL_ACTION_PURCHASE_SUCCESS)
  }

  public actionOnPurchaseSuccess(callback: Function) {
    this.actionOn(MODAL_ACTION_PURCHASE_SUCCESS, callback)
  }

  public actionOffPurchaseSuccess(callback: Function) {
    this.actionOff(MODAL_ACTION_PURCHASE_SUCCESS, callback)
  }

  public actionPurchaseError(): void {
    this.action(MODAL_ACTION_PURCHASE_ERROR)
  }

  public actionOnPurchaseError(callback: Function) {
    this.actionOn(MODAL_ACTION_PURCHASE_ERROR, callback)
  }

  public actionOffPurchaseError(callback: Function) {
    this.actionOff(MODAL_ACTION_PURCHASE_ERROR, callback)
  }

  public actionClose(payload: PaymentModalClosePayload): void {
    this.action(MODAL_ACTION_PURCHASE_CLOSE, payload)
  }

  public actionOnClose(callback: Function) {
    this.actionOn(MODAL_ACTION_PURCHASE_CLOSE, callback)
  }

  public actionOffClose(callback: Function) {
    this.actionOff(MODAL_ACTION_PURCHASE_CLOSE, callback)
  }
}

export const modal = new PaymentModalService()
