import { paymentSolidGateCardDelete } from '@/src/shared/api'

type ApiResponse = {
  success: boolean
}

export async function remove(cardId: number): Promise<boolean> {
  const response = await paymentSolidGateCardDelete<ApiResponse>(cardId)
  return response.isOk && !!response.data?.success
}
