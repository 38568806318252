import { IUserAvatar } from '.'

export class UserAvatarMapper {
  public static dtoToSmallString(dto: IUserAvatar | null): string | null {
    if (!dto) return dto as null
    if (dto.urlS) return dto.urlS
    if (dto.urlB) return dto.urlB
    if (dto.urlF) return dto.urlF
    return null
  }

  public static dtoToBigString(dto: IUserAvatar | null): string | null {
    if (!dto) return dto as null
    if (dto.urlB) return dto.urlB
    if (dto.urlO) return dto.urlO
    if (dto.urlF) return dto.urlF
    if (dto.urlS) return dto.urlS
    if (dto.urlT) return dto.urlT
    return null
  }
}
