// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GdY6[data-v-7483ba1e]{position:relative;padding-top:16px}.I0ug[data-v-7483ba1e]{position:absolute;top:0;left:0;right:0}._2DOc[data-v-7483ba1e],.I0ug[data-v-7483ba1e]{display:flex;justify-content:center}._2DOc[data-v-7483ba1e]{flex-direction:column;align-items:center;width:210px;min-height:53px;border:1px solid #ffad45;border-radius:7px;background-color:#fff}.DDvL[data-v-7483ba1e]{font-size:13px;font-weight:600;line-height:20px}._2Izu[data-v-7483ba1e],.DDvL[data-v-7483ba1e]{font-style:normal;letter-spacing:-.31px;text-align:center}._2Izu[data-v-7483ba1e]{color:#ffad45;font-size:14px;font-weight:700;line-height:21px}.kl37[data-v-7483ba1e]{padding-top:16px}.-olA[data-v-7483ba1e]{padding:60px 12px 16px;border:1px solid #ffad45;border-radius:14px;text-align:center}@media screen and (min-width:1024px){.GdY6 ._2DOc[data-v-7483ba1e]{font-weight:600}.GdY6 .-olA[data-v-7483ba1e]{padding:60px 28px 20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comp": "GdY6",
	"comp__headerWrap": "I0ug",
	"comp__header": "_2DOc",
	"comp__title": "DDvL",
	"comp__sales": "_2Izu",
	"comp__wrap": "kl37",
	"comp__border": "-olA"
};
module.exports = ___CSS_LOADER_EXPORT___;
