import { PaymentCard, PaymentCardVendor } from '../types'
import { usePaymentCardsStore } from './cards.store'
import { remove } from './cardRemove.setup'
import { paymentSolidGateCardGet } from '@/src/shared/api'

type PaymentApiPaymentSolidCardResponseCard = {
  id: number
  brand:
    | 'VISA'
    | 'MASTERCARD'
    | 'AMEX'
    | 'AMERICAN EXPRESS'
    | 'CHINA UNIONPAY'
    | 'DINACARD'
    | 'DINERS CLUB'
    | 'DISCOVER'
    | 'ELO'
    | 'LOCAL BRAND'
    | 'MAESTRO'
    | 'NSPK MIR'
    | 'RUPAY'
    | 'SOLO'
    | 'VERVE'
  number: string
  usedInSubscriptions: boolean
}

type PaymentApiPaymentSolidCardResponse = {
  cards: PaymentApiPaymentSolidCardResponseCard[]
}

function mapper(item: PaymentApiPaymentSolidCardResponseCard): PaymentCard {
  return {
    id: item.id,
    number: item.number,
    brand: vendorMapper(item),
    usedInSubscriptions: item.usedInSubscriptions || false,
  }
}

function vendorMapper(
  item: PaymentApiPaymentSolidCardResponseCard
): PaymentCardVendor {
  switch (item.brand) {
    case 'VISA':
      return 'visa'
    case 'MASTERCARD':
      return 'mastercard'
    case 'AMEX':
      return 'amex'
    case 'MAESTRO':
      return 'maestro'
    case 'DISCOVER':
      return 'discover'
    default:
      return 'other'
  }
}

async function load(): Promise<PaymentCard[]> {
  const store = usePaymentCardsStore()
  const response = await paymentSolidGateCardGet<PaymentApiPaymentSolidCardResponse>()
  if (response.data?.cards) {
    store.$reset() // TODO Найти решение
    response.data.cards.map(mapper).forEach(store.cardAdd)
  }
  store.loaded = true
  return store.cards
}

export async function get(reaload: boolean = false) {
  const store = usePaymentCardsStore()
  if (!store.loaded || reaload) return await load()
  return store.cards
}

export function firstId(): number | undefined {
  const store = usePaymentCardsStore()
  return store.cards.length ? store.cards[0].id : undefined
}

export { remove }
