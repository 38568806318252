import { paymentEntity } from '~/src/entities/payment'
import { userEntity } from '@/src/entities/user'
import { confirmEmailModalEntity } from '~/src/entities/confirm-email-modal'

export function open(entry: paymentEntity.types.PaymentModalEntry): boolean {
  paymentEntity.model.modal.modal.open({ entry })
  return true
}

export function openWithUserAndWait(
  user: userEntity.types.User,
  entry: paymentEntity.types.PaymentModalEntry
): Promise<boolean> {
  return new Promise((resolve) => {
    paymentEntity.model.modal.modal.open({ entry })
    paymentEntity.model.modal.onClose(
      (payment: paymentEntity.types.PaymentModalClosePayload) => {
        if (!user.emailConfirmed && payment.target === 'button') {
          confirmEmailModalEntity.model.open('nocoins')
          confirmEmailModalEntity.model.onCloseOne(() => resolve(true))
        } else {
          resolve(true)
        }
      }
    )
  })
}

const { onClose, offClose } = paymentEntity.model.modal
export { onClose, offClose }
