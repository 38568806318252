import { paypalOrderCapturePost } from '@/src/shared/api'

type PaypalOrderCaptureResponse = {
  success: boolean
}

export async function paypalOrderCapture(id: string): Promise<boolean> {
  const response = await paypalOrderCapturePost<PaypalOrderCaptureResponse>(id)
  if (response.isOk && response.data) {
    return response.data.success
  }
  return false
}
