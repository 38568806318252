import { usePaypalStore } from './paypal.store'
import { paypalAuthenticationClientTokenGet } from '@/src/shared/api'

type PaypalClientTokenResponse = {
  clientId: string
  clientToken: string
}

export async function paypalClientTokenGet() {
  const store = usePaypalStore()
  const response = await paypalAuthenticationClientTokenGet<PaypalClientTokenResponse>()
  if (response.isOk && response.data) {
    store.clientId = response.data.clientId
    store.clientToken = response.data.clientToken
  }
}
