import { defineStore } from 'pinia'

type PaypalStoreState = {
  scriptLoaded: boolean
  clientId: string
  clientToken: string
  orderId: string
  subscriptionId: string
}

export const usePaypalStore = defineStore('paypal', {
  state: (): PaypalStoreState => {
    return {
      scriptLoaded: false,
      clientId: '',
      clientToken: '',
      orderId: '',
      subscriptionId: '',
    }
  },
})
