import { computed, ref } from '@nuxtjs/composition-api'
import { IUserObject, IUserReasonsForRemoveObject } from './user.types'

export enum UserStreamerPhotoValidationStatus {
  None = 0,
  PhotoOnModeration = 1,
  NotPassedModeration = 2,
  Verified = 3,
}

export enum UserFavoritesContext {
  videoCall = 1,
  profile = 2,
}

export const userMe = ref<IUserObject>()
export const userMeId = computed(() => userMe.value?.id)
export const userPreRemoveReasons = ref<IUserReasonsForRemoveObject[]>([])

export const userBalanceLoaded = ref(false)
export const userBalance = ref(0)
