import { defineStore } from 'pinia'
import { PaymentCard } from '../types'

type PaymentCardsStoreState = {
  loaded: boolean
  cards: PaymentCard[]
}

export const usePaymentCardsStore = defineStore('paymentCards', {
  state: (): PaymentCardsStoreState => {
    return {
      loaded: false,
      cards: [],
    }
  },
  getters: {
    isExists: (state) => state.loaded && !!state.cards.length,
    isEmpty: (state) => state.loaded && !state.cards.length,
    cardById: (state) => (id: number) =>
      state.cards.find((card: PaymentCard) => card.id === id),
  },
  actions: {
    cardAdd(card: PaymentCard) {
      this.cards.push(card)
    },
  },
})
