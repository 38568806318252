import { Subject } from 'rxjs'
import { onBeforeUnmount } from '@nuxtjs/composition-api'

const purchaseSucces$ = new Subject()

export function emitPurchaseSuccess() {
  purchaseSucces$.next(undefined)
}

export function onPurchaseSuccess(callback: Function) {
  const purchaseSucces = purchaseSucces$.subscribe({
    next: () => callback(),
  })
  onBeforeUnmount(() => purchaseSucces.unsubscribe())
}
