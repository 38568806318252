// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oI5D[data-v-5a999c6b]{width:100%}@media screen and (min-width:1024px){.oI5D[data-v-5a999c6b]{width:374px;margin-left:auto;margin-right:auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comp": "oI5D"
};
module.exports = ___CSS_LOADER_EXPORT___;
