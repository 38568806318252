import { ref } from '@nuxtjs/composition-api'
import { PaymentError, PaymentErrorDescription } from '../payment.error'
import { usePaymentModalStore } from './modal.store'
import {
  SdkLoader as solidLoader,
  ClientSdk,
  ClientSdkInstance,
  InitConfig,
} from '@/src/shared/lib/solid-sdk'
import { paymentSolidGateGetPaymentPost } from '@/src/shared/api'

type PaymentApiPaymentSolidGetPayment = {
  signature: string
  merchant: string
  paymentIntent: string
  orderId: number
}

const solidSDK = ref<ClientSdk>()
const solidSDKForm = ref<ClientSdkInstance>()

async function solidMerchantDataLoad(): Promise<
  PaymentApiPaymentSolidGetPayment | undefined
> {
  const store = usePaymentModalStore()
  if (!store.selectedBundleSku)
    throw new PaymentError(PaymentErrorDescription.NoSKU)
  const response = await paymentSolidGateGetPaymentPost<PaymentApiPaymentSolidGetPayment>(
    store.selectedBundleSku
  )
  if (response.isOk) {
    if (response.data?.orderId) {
      store.orderId = response.data?.orderId
    }
    return response.data
  }
}

export async function solidInit() {
  const store = usePaymentModalStore()
  solidSDK.value = await solidLoader.load()
  store.solidLoaded = true
}

export async function solidFormInit(configSet: Partial<InitConfig>) {
  if (!solidSDK.value)
    throw new PaymentError(PaymentErrorDescription.NoSolidSDK)
  const merchantData = await solidMerchantDataLoad()
  if (!merchantData)
    throw new PaymentError(PaymentErrorDescription.NoMerchantData)
  const store = usePaymentModalStore()
  const config = Object.assign(
    {
      merchantData: {
        merchant: merchantData.merchant,
        signature: merchantData.signature,
        paymentIntent: merchantData.paymentIntent,
      },
    },
    configSet
  )
  solidSDKForm.value = solidSDK.value.init(config)
  store.solidInit = true
  return solidSDKForm.value
}

export function solidFormGet() {
  if (!solidSDK.value)
    throw new PaymentError(PaymentErrorDescription.NoSolidSDK)
  if (!solidSDKForm.value)
    throw new PaymentError(PaymentErrorDescription.NoSolidSDKForm)
  return solidSDKForm.value
}

export function solidDestroy() {
  if (!solidSDK.value) return
  const store = usePaymentModalStore()
  solidSDK.value.destroy()
  solidSDK.value = undefined
  store.solidLoaded = false
  store.solidInit = false
}
