import { defineStore } from 'pinia'
import {
  PaymentModalEntry,
  PaymentModalScreenStep,
  PaymentBundle,
} from '../../types'

type UserStoreState = {
  open: boolean
  loading: boolean
  entry: PaymentModalEntry
  screenStep: PaymentModalScreenStep
  isFirstPay: boolean
  isOrganic: boolean
  bundleName: string
  selectedCardId: string
  selectedBundle: PaymentBundle | undefined
  paymentMethod: '' | 'solid' | 'paypal'
  paymentStart: number
  orderId: number
  errorMessage: string
  errorCode: string
  secureSrc: string
  solidLoaded: boolean
  solidInit: boolean
}

export const usePaymentModalStore = defineStore('paymentModal', {
  state: (): UserStoreState => {
    return {
      open: false,
      loading: true,
      entry: 'none',
      screenStep: 'none',
      isFirstPay: false,
      isOrganic: false,
      bundleName: '',
      selectedCardId: '',
      selectedBundle: undefined,
      paymentMethod: '',
      paymentStart: 0,
      orderId: 0,
      secureSrc: '',
      errorMessage: '',
      errorCode: '',
      solidLoaded: false,
      solidInit: false,
    }
  },
  getters: {
    isStepBundles: (state) => state.screenStep === 'bundles',
    isStepSolid: (state) => state.screenStep === 'form',
    isStepSecure: (state) => state.screenStep === 'secure',
    isStepSuccess: (state) => state.screenStep === 'success',
    isStepError: (state) => state.screenStep === 'error',
    selectedBundleSku: (state) => state.selectedBundle?.sku,
  },
  actions: {
    cardSelect(id: string) {
      this.selectedCardId = id
    },
    selecteBundle(bundle: PaymentBundle) {
      this.selectedBundle = bundle
    },
  },
})
