import { modal } from './modal.service'

export function onCloseOne(callback: Function) {
  const onClose = () => {
    callback.apply(arguments)
    modal.actionOffClose(onClose)
  }
  modal.actionOnClose(onClose)
}

export function onClose(callback: Function) {
  modal.actionOnClose(callback)
}

export function offClose(callback: Function) {
  modal.actionOffClose(callback)
}
